<template>
    <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false"  />
      <v-card  :color="$store.getters.getColorPalette().background2ColorCode"  :dark="$store.getters.getColorPalette().isDark">
        <v-card-title>
          <v-icon  color="yellow"  small>mdi-cellphone-arrow-down</v-icon>&nbsp;<strong style="align-self:centre" :color="$store.getters.getColorPalette().accentCode">  {{ $store.getters.getTextMap().update_org_alias }} </strong>
        </v-card-title>
        <v-card-subtitle>
          <v-form ref="orgForm" v-model="valid"  >
            <v-row  align="center" justify="space-around">
              <v-col  cols="12" md="4">
                <v-text-field
                  color="blue-grey lighten-1"
                  v-model="form.alias" 
                  :label="mode === $store.getters.getTextMap().create ? $store.getters.getTextMap().organisation_alias : $store.getters.getTextMap().update_organisation_alias"
                  :value="aliasName" 
                  dense
                  outlined
                  :rules="nameRules"
                  :counter="50"
                  :loading="loading"
                  :dark="$store.getters.getColorPalette().isDark"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn :color="$store.getters.getColorPalette().submitbtnColor" v-if="!loading" @click="onSubmit">
               {{ $store.getters.getTextMap().submit }}
                </v-btn>
                <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
   </v-col>
   <v-col>
                <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" v-if="!loading" @click="onClose">
                  {{ $store.getters.getTextMap().cancel }}
                </v-btn>
                <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card-subtitle>
      </v-card>
    </v-container>
</template>
<script>

import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'

export default {
    name:'UpdateOrgDetails',
    components:{
        InfoAlert,
    },
     props: {
    aliasName: String, 
  
  },
    mounted(){
    if (this.form.alias) {
      this.mode = 'update';
    } else {
      this.mode = 'create'; 
    }
    },
    data(){
        return {

                   form: {
             alias: this.aliasName,
        },
            mode: 'create',
            api: {
                create: 'createOrgAlias',
                update: 'updateOrgAlias'
                },
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
            dialog:false,
            showForm:false,            
            nameRules: [
              v => !!v ||  this.$store.getters.getTextMap().name_is_required,
              v => /\S+/.test(v) ||  this.$store.getters.getTextMap().name_is_required,
              v => (v && v.length <= 50 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_50_characters,
            ],
        }
    },
    methods:{
      onClose(){
            this.dialog=false
            this.$emit('close')
        },
        onSubmit() {
            this.$refs.orgForm.validate()
            if (this.valid) {
                this.loading = true;
            const api = this.api[this.mode] 
               let payload = {
        alias: this.form.alias, 
    };
    console.log(payload,"payload")
                axios.post(this.$store.state.api+api, payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                    .then(response => {
                        if (response.data.status == "success") {
                            console.log("changes")
                            this.$store.dispatch("refreshOrganisation");
                            this.$store.dispatch("refreshUserData");
                            this.info = this.mode === 'create' ? 'Alias Created' : 'Alias Updated'
                            this.showDismissibleAlert = true;
                            this.mode = 'update'
                            this.$emit('close')
                        } else {
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                        this.loading = false;
                    })
                    .catch(error => {
                        // this.onReset();
                        this.info = error;
                        this.showDismissibleAlert = true;
                        this.loading = false;
                    });
            }
        },
      toggleForm (){
          this.showForm = !this.showForm
      }  
    }
}
</script>
<style scoped>

</style>