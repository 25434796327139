<template>
  <div class="logo-frame-container">
    <v-hover v-slot="{ hover }">
      <div>
        <div class="round-logo-frame">
          <div class="image-container">
            <img
            v-if="$store.state.organisation"
              :src="$store.state.organisation.logo"
              alt="Organisation Logo"
              class="round-logo"
            />
          </div>

          <v-overlay   absolute :value="hover">

              <v-btn   
              @click="dialog = true"   
              :color="$store.getters.getColorPalette().submitbtnColor"
               small               
               >
                <v-icon left>
                  mdi-pencil
                </v-icon>
              {{ $store.getters.getTextMap().update_logo }}
              </v-btn>
        
          </v-overlay>

          <v-dialog
      v-model="dialog"
    >        
<UpdateLogo  v-on:close="onClose"/>
          </v-dialog>
        </div>
      </div>
    </v-hover>
  </div>
</template>



<script>
 import UpdateLogo from '@/components/crud_components/UpdateLogo'
export default {
    name:'logoFrame',
    
    components:{
        UpdateLogo
    },
 data(){
     return {
    showDismissibleAlert: false,
    loading: false,
    info:'',
    logoUrl:null,
    dialog: false,
     }
 },
 mounted(){
        // this.getLogo()
        // console.log("kkk")
      },
  methods:{
   onClose(){
            this.dialog=false
            this.$emit('close')
        }
  },
};
</script>

<style scoped>
.round-logo-frame {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  position: relative; 
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.round-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

