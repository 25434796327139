<template>
    <v-container fluid>
        <v-row v-if="$store.state.user && $store.state.settingMode" dense>
            <v-col cols="auto" class="d-flex"  >
                <UpdateOrgDetailsModal />
            </v-col>
            <v-col cols="auto" class="d-flex"  >
                <UpdateOrgaliasModal :data="OrgAlias" v-on:close="getOrgAlias()" />
            </v-col>
        </v-row>
        
        <v-row  v-if="$store.state.user">
               
            <v-col >
            <v-card outlined :color="$store.getters.getColorPalette().background2ColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-row align="center" justify="center">
                <v-col align="center" justify="center" >
                <logoFrame />
                </v-col>
                </v-row>
                <v-row >
                <v-col>
                 <v-card-title> <span :color="$store.getters.getColorPalette().accentCode">  Organization Name: </span> &nbsp; &nbsp;<strong><span class="orange--text">{{$store.state.organisation?$store.state.organisation.name:'-'}}</span></strong>
                  </v-card-title>
                </v-col>
                
          
                
            </v-row>
            <v-divider></v-divider>
            <v-card-text> 
                <v-row>
                    <v-col>
                    <span  :color="$store.getters.getColorPalette().accentCode"><strong> Organization ID:</strong></span> &nbsp; &nbsp;<span class="grey--text">{{$store.state.organisation?$store.state.organisation.organisation_id:'-'}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
                        <v-card-text> 
                <v-row>
                    <v-col>
                    <span  :color="$store.getters.getColorPalette().accentCode"><strong> Organization Alias:</strong></span> &nbsp; &nbsp;<span class="grey--text">{{OrgAlias.alias}}</span>
                    </v-col>
                </v-row>
            </v-card-text>
                <v-divider></v-divider>
            <v-card-text> 
            <v-row>
                <v-col>

                    <p :color="$store.getters.getColorPalette().accentCode"><strong>Address</strong> <v-icon color="yellow"> mdi-home-search</v-icon> : <span class=" grey--text"> {{$store.state.organisation?$store.state.organisation.address:'-'}}</span></p>
                    <p :color="$store.getters.getColorPalette().accentCode"><strong>Country</strong> <v-icon color="yellow"> mdi-earth</v-icon> : <span class=" grey--text"> {{$store.state.organisation?$store.state.organisation.country_code:'-'}}</span></p>
                    <p :color="$store.getters.getColorPalette().accentCode"><strong>State </strong> <v-icon color="yellow"> mdi-google-maps </v-icon> : <span class=" grey--text"> {{$store.state.organisation?$store.state.organisation.state:'-'}} </span></p>
                    <p :color="$store.getters.getColorPalette().accentCode"><strong>Timezone </strong> <v-icon color="yellow"> mdi-clock </v-icon> : <span class=" grey--text"> {{$store.state.organisation?$store.state.organisation.timezone:'-'}} </span></p>

                 

                </v-col>
            </v-row>
            </v-card-text>
            <v-divider></v-divider>
            </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import UpdateOrgDetailsModal from '@/components/modals/UpdateOrgDetailsModal'
import UpdateOrgaliasModal from '@/components/modals/UpdateOrgaliasModal'
import logoFrame from '@/components/display_components/logoFrame'
export default {
    name:'OrganisationDetails',
    data(){
        return {
             OrgAlias:{},
        }
    },
    components:{
        UpdateOrgDetailsModal,
        UpdateOrgaliasModal,
        logoFrame
    },
    mounted() {
       // console.log("check")
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
   
   this.getOrgAlias()

    },
    methods:{ 
        getOrgAlias(){
              //console.log("getOrgAlias() method called."); 
            axios.get(this.$store.state.api+'getAlias',{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
             }}).then(response=>{ 
                //console.log(response);
                    if(response.data.status==='success'){
                        this.OrgAlias=response.data.data
                        //console.log(this.OrgAlias,"jj")
                    this.loading=false
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
            }}).catch(err=>{
                console.log(err)
                    this.info = err;
                    this.showDismissibleAlert = true;
                    this.loading=false
            })
        }
    }
}
</script>
<style scoped>
.container{
    margin-top: -1%;
}
/* Add this CSS to show the pencil icon on hover */
.logoFrame:hover .edit-icon {
  display: inline-block;
}

/* Style for the pencil icon */
.edit-icon {
  display: none;
  font-size: 1.5em;
  margin-left: 5px;
  color: #333; /* Adjust the color as needed */
}
</style>