<template>
    <v-container fluid>
        <v-row>
            <v-col align="right">
                <v-btn
                    :background-color="$store.getters.getColorPalette().background1ColorCode"
                    :color="$store.getters.getColorPalette().accentCode"
                    small 
                    text
                    :dark="$store.getters.getColorPalette().isDark"
                    outlined
                    @click="dialog = !dialog"
                >
                    {{alias}}<v-icon right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor">mdi-pencil</v-icon>
                </v-btn>
                <v-dialog v-model="dialog" >  
                    <UpdateOrgalias v-on:close="onClose" :aliasName="data.alias" @updateAlias="updateAliasName" />
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template> 
<script>
import UpdateOrgalias from '@/components/crud_components/UpdateOrgalias'
export default {
    name:'UpdateOrgDetailsModal',
    components:{
        UpdateOrgalias
    },
    props:{
        data:Object,
       
    },
    data () {
    return {
        dialog: false,
        valid:false,
        orgAlias: false
    }
    },
computed: {
alias() {
        return this.data.alias !== null ? this.$store.getters.getTextMap().update_org_alias : 'Create Org Alias';
    }
},
    methods:{
        onClose(){
            this.dialog=false
            this.$emit('close')
        },
        updateAliasName(mode) {
      if (mode === 'create') {
        this.orgAlias = true; 
      }
    }
    }
}
</script>
<style scoped>
.emailgrp{
    border:3px solid;
    border-radius:20px;
}
.theme--light.v-card{
    background-color: #263238
}
</style>