<template>
  <v-container fluid :style="{ background: $store.getters.getColorPalette().backgroundColorCode }">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert = false" />
    <v-card :color="$store.getters.getColorPalette().background2ColorCode"
      :dark="$store.getters.getColorPalette().isDark">
      <v-card-title>
        <v-icon color="yellow" small>mdi-cellphone-arrow-down</v-icon>&nbsp;<strong style="align-self:centre"
          :color="$store.getters.getColorPalette().accentCode"> {{
            $store.getters.getTextMap().update_your_organization_details }}</strong>
      </v-card-title>
      <v-card-subtitle>
        <v-form ref="orgForm" v-model="valid">
          <v-row align="center" justify="space-around">
            <v-col cols="12" md="4">
              <v-text-field color="blue-grey lighten-1" v-model="form.name" dense outlined :rules="nameRules"
                :counter="50" :loading="loading" :label="$store.getters.getTextMap().organisation_name"
                :dark="$store.getters.getColorPalette().isDark" required></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field dense outlined color="blue-grey lighten-1" v-model="form.address" :rules="addressRules"
                :counter="50" :loading="loading" :label="$store.getters.getTextMap().address" required></v-text-field>
            </v-col>
            <v-col v-if="$store.state.user && $store.getters.getAccessByLevels(['betaaccess'])">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-select v-if="!loading" v-model="form.default_email" :items="defaultEmailList"
                :label="$store.getters.getTextMap().default_email" dense outlined item-text="email" item-value="email"
                required :rules="selectRules"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-select v-if="!loading" v-model="form.theme" :items="$store.getters.getThemeList()"
                :label="$store.getters.getTextMap().theme" dense outlined item-text="label" item-value="value" required
                :rules="[rules.required]" :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
            </v-col>

            <!-- Country -->
            <v-col cols="12" md="4" v-if="$store.state.user && $store.getters.getAccessByLevels(['betaaccess'])">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-select v-if="!loading" v-model="countryCode" :items="countryCodes"
                :label="$store.getters.getTextMap().country" dense outlined item-text="name" item-value="code" required
                :rules="selectRules" :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
            </v-col>


            <!-- State -->
            <v-col cols="12" md="4" v-if="$store.state.user && $store.getters.getAccessByLevels(['betaaccess'])">
              <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-select v-if="!loading" v-model="state" :items="states"
                :label="$store.getters.getTextMap().state" dense outlined item-text="state" item-value="code" required
                :rules="selectRules" :menu-props="{ dark: $store.getters.getColorPalette().isDark }"></v-select>
            </v-col>

            <!-- timezones -->
            <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
              <v-col cols="12" v-if="!loading">
                <v-select v-if="!loading" v-model="timezone" dense  outlined label="Timezone" :items="timezones" item-text="timezone" item-value="timezone">
                </v-select>
              </v-col>




            <v-col>
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                :return-value.sync="form.usage_start_time" transition="scale-transition" offset-y max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.usage_start_time" :label="$store.getters.getTextMap().consumption_start_time"
                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-time-picker v-if="menu" v-model="form.usage_start_time" full-width format="24hr" scrollable
                  @click:minute="$refs.menu.save(form.usage_start_time)"></v-time-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-checkbox :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor" v-if="!loading" v-model="form.diagnostics"
                :label="`Hub Diagnostics`"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor" v-if="!loading"
                v-model="form.param_diagnostics" :label="$store.getters.getTextMap().param_diagnostics"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor" v-if="!loading" v-model="form.show_logo"
                :label="$store.getters.getTextMap().show_logo"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor" v-if="!loading" v-model="form.showoem_logo"
                :label="$store.getters.getTextMap().show_oem_logo"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-btn :color="$store.getters.getColorPalette().submitbtnColor" class="mx-2" v-if="!loading"
                @click="onSubmit">
                {{ $store.getters.getTextMap().submit }}
              </v-btn>
              <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
              <v-btn :color="$store.getters.getColorPalette().cancelbtnColor" class="m-2" v-if="!loading"
                @click="onClose">
                {{ $store.getters.getTextMap().cancel }}
              </v-btn>
              <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-subtitle>
    </v-card>
  </v-container>
</template>
<script>

import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'

export default {
  name: 'UpdateOrgDetails',
  components: {
    InfoAlert,
  },
  mounted() {
    this.init()
  },
  data() {
    return {
      defaultEmailList: [],
      form: {
        organisation_id: null,
        name: null,
        address: null,
        theme: '',
        default_email: 'info@nebeskie.com'
      },
      timezone:null,
      timezones:null,
      edit: false,
      loading: false,
      info: '',
      showDismissibleAlert: false,
      valid: false,
      dialog: false,
      showForm: false,
      menu: null,
      rules: FormRules.rules,
      nameRules: [
        v => !!v || this.$store.getters.getTextMap().name_is_required,
        v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
        v => (v && v.length <= 50 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_50_characters,
      ],
      addressRules: [
        v => !!v || this.$store.getters.getTextMap().required,
        v => /\S+/.test(v) || this.$store.getters.getTextMap().required,
        v => (v && v.length <= 50 && v.length > 0) || this.$store.getters.getTextMap().must_be_less_than_50_characters,
      ],
      selectRules: [
        v => (v != null) || this.$store.getters.getTextMap().required
      ],
      countryCode: null,
      state: null, 
      states:null

    }
  },


  computed: {
    countryCodes() {
      let op = []
      if (this.$store.state.countryCodes) {
        this.$store.state.countryCodes.forEach((item) => {
          op.push(
            {
              name: item.name,
              code: item.code
            }
          )
        })

      }
      this.getStates()
      return op


    }
  },
  methods: {
    init(){
    this.form = Object.assign({}, this.$store.state.organisation)
    
    if(this.form){
      this.countryCode = this.form.country_code
      this.state = this.form.state
      this.timezone = this.form.timezone
    }
    this.getDefaultEmails()
    this.getTimezones()
    },

    getTimezones(){
      this.loading=true;
      axios.get(this.$store.state.api + "/getTimezones",{
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        }).then(res=>{
          if (res.data.status == "success") {
            let data = res.data.data   
            this.timezones=[...data]
            this.loading=false
          }else{
           this.loading=false
          }
        }).catch(err=>{
          console.log(err);
          this.info=err
          
        })
    
    },

    getStates() {
      if (this.countryCode) {
        let payload = {
          country_code: this.countryCode
        }
        axios.post(this.$store.state.api + '/getStates', payload, {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.jwt
          }
        }).then(response => {
          if (response.data.status == "success") {

            let data = response.data.data


            this.states = []

            data.forEach(item => {
              this.states.push({
                state: item.state,
                code: item.code
              })
            })

          }
        })

      }


    },


    getDefaultEmails() {
      this.defaultEmailList = []
      axios.get(this.$store.state.api + "defaultEmailList", { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
        .then(response => {
          if (response.data.status == "success") {
            this.defaultEmailList = response.data.default_emails
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    },
    onClose() {
      this.dialog = false
      this.$emit('close')
    },
    onSubmit() {
      this.$refs.orgForm.validate()
      if (this.valid) {
        this.loading = true;

        this.form['country_code']=this.countryCode
        this.form['state']=this.state
                this.form['timezone'] = this.timezone
        
        axios.post(this.$store.state.api + "updateOrganisationDetails", this.form, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
          .then(response => {
            if (response.data.status == "success") {
              this.$store.dispatch("refreshOrganisation");
              this.$store.dispatch("refreshUserData");
              this.info = 'Details Updated';
              this.showDismissibleAlert = true;
              this.edit = !this.edit
              this.$emit('close')
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch(error => {
            this.onReset()
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
          });
      }
    },
    toggleForm() {
      this.showForm = !this.showForm
    }
  },
}
</script>
<style scoped></style>